import React, {useEffect, useState} from 'react'

import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
import {gsap} from 'gsap'
import SVG from 'react-inlinesvg'
import confetti from 'canvas-confetti'

import 'react-toastify/dist/ReactToastify.css'
import './App.css'

import teaser from './assets/img/teaser.jpg'
import presentWhite from './assets/img/present_white_closed.png'
import presentWhiteOpen from './assets/img/present_white.svg'
import presentBlue from './assets/img/present_blue_closed.png'
import presentBlueOpen from './assets/img/present_blue.svg'
import presentGrey from './assets/img/present_grey_closed.png'
import presentGreyOpen from './assets/img/present_grey.svg'
import raphaelhaus from './assets/img/raphaelhaus.png'
import tree from './assets/img/tree.jpg'

function App() {
  const [price, setPrice] = useState(null)
  const [present, setPresent] = useState(null)
  const [code, setCode] = useState(null)
  const [shouldPlayAnimation, setShouldPlayAnimation] = useState(false)
  const [errorText, setErrorText] = useState(null)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')

    if (code) {
      setCode(code)
    } else {
      toast('Kein Code vorhanden.', {type: toast.TYPE.ERROR})
    }
  }, [])

  useEffect(() => {
    switch (price) {
      case 25:
        setPresent(presentBlueOpen)
        break
      case 20:
        setPresent(presentWhiteOpen)
        break
      case 15:
        setPresent(presentGreyOpen)
        break
      default:
    }
  }, [price])

  const svgClass = 'w-48 lg:w-full'

  const openPackageAnimation = () => {
    const timeline = gsap.timeline()
    const presentContainer = document.getElementById('presentContainer')
    const presentTop = document.querySelectorAll('#top')
    const presentPrice = document.querySelectorAll('#price')

    timeline
      .to(presentTop, {delay: 0.75, duration: 0.5, y: 0})
      .to(presentPrice, {duration: 0.5, y: 0})

    presentContainer.confetti = confetti.create(presentContainer, {
      resize: true,
    })

    var count = 400
    var defaults = {
      origin: {y: 0.7},
    }

    function fire(particleRatio, opts) {
      presentContainer.confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio),
        }),
      )
    }

    setTimeout(() => {
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      })
      fire(0.2, {
        spread: 60,
      })
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      })
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      })
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      })
    }, 1500)

    return timeline
  }

  const sendDonation = donation => {
    if (code) {
      const url = window.location.origin || process.env.REACT_APP_API_URL
      axios
        .post(`${url}/api/donation/add`, {
          hash: code,
          value: donation,
        })
        .then(res => {
          if (res.status === 200 && !res.data.error) {
            setPrice(donation)
            setShouldPlayAnimation(true)
          } else if (res.status === 200 && res.data.error && res.data.text) {
            setPrice(donation)
            setErrorText(res.data.text)
          } else {
            toast('Es ist ein Fehler aufgetreten.', {type: toast.TYPE.ERROR})
          }
        })
        .catch(e =>
          toast('Es ist ein Fehler aufgetreten.', {type: toast.TYPE.ERROR}),
        )
    } else {
      toast('Kein Code vorhanden.', {type: toast.TYPE.ERROR})
    }
  }

  return (
    <div className="bg-green ">
      <ToastContainer position="bottom-center" />
      <div className="flex items-center pl-4 gap-6 lg:pl-36 pt-6 lg:gap-10">
        <div>
          <img className="w-12 lg:w-24" src="logo.png" alt="mwb-logo" />
        </div>
        <div className="text-lg uppercase">MWB Weihnachtsaktion</div>
      </div>

      {price && (
        <div className="relative mt-16 lg:mt-24 lg:ml-36">
          <div className="bg-mwbBlue px-8 py-8 w-full text-white 2xl:absolute 2xl:w-2/4 2xl:left-32 2xl:-top-8">
            <div className="relative triangle-top-blue">
              {shouldPlayAnimation && (
                <>
                  <h1 className="text-2xl font-bold mb-4 lg:text-4xl lg:mb-8">
                    Vielen Dank fürs Mitmachen!
                  </h1>
                  <p className="text-lg lg:text-xl">
                    Wir spenden nun für Sie {price} € an das Raphaelhaus. Wir
                    wünschen Ihnen und Ihrer Familie erholsame Feiertage und
                    einen guten Start in das neue Jahr.
                  </p>
                </>
              )}
              {!shouldPlayAnimation && (
                <>
                  <h1 className="text-2xl font-bold mb-4 lg:text-4xl lg:mb-8">
                    {errorText}
                  </h1>
                  <p className="text-lg lg:text-xl">
                    Wir spenden für Sie an das Raphaelhaus. Wir wünschen Ihnen
                    und Ihrer Familie erholsame Feiertage und einen guten Start
                    in das neue Jahr.
                  </p>
                </>
              )}
              <div className="bg-white">
                <a
                  className="text-white font-bold text-xl"
                  href="https://mwb.info/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="btn w-full border border-mwbBlue text-mwbBlue mt-8 px-8 py-4 text-center hover:bg-mwbWhiteHover hover:text-mwbTextHover cursor-pointer">
                    Zur MWB-Website
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div>
            <img src={tree} alt="tree" />
          </div>
          <div className="absolute left-3/4 bottom-0 -translate-x-1/2 translate-y-1/2 xl:translate-y-0">
            <div className="relative">
              <SVG
                key={svgClass}
                className={svgClass}
                src={present}
                onLoad={() => {
                  if (shouldPlayAnimation) {
                    const animation = openPackageAnimation()
                    animation.play()
                  }
                }}
              />
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <canvas
                  id="presentContainer"
                  className="h-[48rem] w-48 xl:w-[44rem]"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {!price && (
        <div>
          <div className="relative mt-16 lg:mt-24 lg:ml-36">
            <div className="bg-mwbBlue px-8 py-8 w-full text-white 2xl:absolute 2xl:w-2/4 2xl:left-32 2xl:-top-8">
              <div className="relative triangle-top-blue">
                <h1 className="text-2xl font-bold mb-4 lg:text-4xl lg:mb-8">
                  Liebe Geschäftspartner*innen,
                </h1>
                <p className="text-lg lg:text-xl">
                  gemeinsam haben wir das aufregende Jahr 2022 gemeistert. Dafür
                  bedanken wir uns mit einer Spendenaktion für das Raphaelhaus.
                  Hiermit wollen wir die geplante Osterfahrt für die Kinder und
                  Jugendlichen im nächsten Jahr unterstützen. Einfach anklicken
                  und wir spenden anschließend für Sie.
                </p>
                <div className="bg-white">
                  <a className="text-white font-bold text-xl" href="#geschenke">
                    <div className="btn w-full border border-mwbBlue text-mwbBlue mt-8 px-8 py-4 text-center hover:bg-mwbWhiteHover hover:text-mwbTextHover cursor-pointer">
                      Paket auspacken
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <img src={teaser} alt="teaser" />
            </div>
          </div>

          <div id="geschenke" className="mt-6 px-8 lg:mt-16 lg:ml-36 lg:pl-0">
            <h1 className="font-[Lato-Bold] text-2xl text-mwbBlue font-bold lg:text-6xl">
              Wir spenden für Sie
            </h1>
            <div className="relative mt-3 border-t-2 border-mwbBlue">
              <div className="absolute w-4 left-10 lg:w-10 lg:left-32">
                <svg viewBox="0 0 100 100">
                  <polygon
                    id="e1_polygon"
                    points="0 0, 0 100, 100 0"
                    fill="#00A3DA"
                  ></polygon>
                </svg>
              </div>
            </div>

            <div className="mt-6 lg:mt-24 lg:w-1/3">
              <p className="text-xl lg:text-2xl">
                <span className="font-bold">Jetzt sind Sie dran!</span> In jedem
                Paket haben wir einen Betrag versteckt. Klicken Sie auf ein
                Geschenk – wir spenden die Summe an das Raphaelhaus in Mülheim.
              </p>
            </div>

            <div className="mt-6 flex flex-col md:flex-row md:justify-center">
              <div className="self-center">
                <img
                  id="present"
                  className={`w-48 md:w-3/4 ${code ? 'cursor-pointer' : ''}`}
                  src={presentWhite}
                  alt="presentWhite"
                  onClick={() => {
                    if (code) {
                      sendDonation(20)
                    }
                  }}
                />
              </div>
              <div className="self-center">
                <img
                  id="present"
                  className={`w-48 md:w-3/4 ${code ? 'cursor-pointer' : ''}`}
                  src={presentBlue}
                  alt="presentBlue"
                  onClick={() => {
                    if (code) {
                      sendDonation(25)
                    }
                  }}
                />
              </div>
              <div className="self-center">
                <img
                  id="present"
                  className={`w-48 md:w-3/4 ${code ? 'cursor-pointer' : ''}`}
                  src={presentGrey}
                  alt="presentGrey"
                  onClick={() => {
                    if (code) {
                      sendDonation(15)
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div>
            <div className="mt-6 px-8 lg:mt-16 lg:ml-36 lg:pl-0">
              <span className="lg:text-xl">Für eine gute Sache</span>
              <h1 className="font-[Lato-Bold] text-2xl font-bold text-mwbBlue lg:text-6xl">
                Das Raphaelhaus
              </h1>
              <div className="relative mt-3">
                <div className="absolute w-4 left-10">
                  <svg viewBox="0 0 100 100">
                    <polygon
                      id="e1_polygon"
                      points="0 0, 0 100, 100 0"
                      fill="#ffffff"
                    ></polygon>
                  </svg>
                </div>
              </div>
            </div>
            <div className="relative lg:mb-80">
              <img className="lg:w-2/3" src={raphaelhaus} alt="raphaelhaus" />

              <div className="mt-2 px-8 bg-white lg:absolute lg:left-36 lg:-bottom-36 lg:w-1/3 lg:px-12 lg:py-8">
                <p className="text-xl">
                  <span className="font-bold">Das Raphaelhaus</span> ist eine
                  Einrichtung der freien Kinder- und Jugendhilfe. Es bietet
                  stationäre und ambulante Betreuung für Kinder, Jugendliche und
                  Familien. Träger sind die Vereinigten August
                  Thyssen-Stiftungen.
                </p>
                <a
                  className="text-white font-bold text-xl"
                  href="https://www.raphaelhaus.de/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="btn w-full bg-mwbBlue mt-8 px-8 py-4 text-center hover:bg-mwbBlueHover cursor-pointer">
                    www.raphaelhaus.de
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={`${
          price ? 'fixed bottom-0' : ''
        } w-full mt-16 py-4 bg-mwbBlue text-center text-white font-bold`}
      >
        <a
          className="pr-2 border-r border-white hover:underline"
          href="https://www.mwb.info/impressum"
          target="_blank"
          rel="noreferrer"
        >
          Impressum
        </a>
        <a
          className="pl-2 hover:underline"
          href="https://www.mwb.info/datenschutz"
          target="_blank"
          rel="noreferrer"
        >
          Datenschutz
        </a>
      </div>
    </div>
  )
}

export default App
